<template>
    <custom-table
        :title="$t('pages.system.linkManager.title')"
        :subTitle="$t('pages.system.linkManager.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="SystemLinkManagerFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:title="{ row: record }">
            {{ record.title ?? "-" }}
        </template>
        <template v-slot:slug="{ row: record }">
            <div class="d-flex align-items-center">
                {{ record.slug }}
                <div v-if="['301', '302'].includes(record.linkable_type)" class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 me-1 ms-1">
                        <inline-svg src="/media/icons/duotune/arrows/arr024.svg" />
                    </span>
                    <b>{{ record.redirect_slug }}</b>
                </div>
            </div>
        </template>
        <template v-slot:locale="{ row: record }">
            <span class="symbol symbol-20px">
                <img class="rounded-1" :src="sprintf($root.flagPath, [record.language.code_lower])" :alt="record.language.name"/>
            </span>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchLink(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm v-if="['301', '302'].includes(record.linkable_type)" :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_link" ref="linkModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_link_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_link_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitLink()" :model="form.data" ref="linkForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_link_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <el-divider content-position="center">
                                {{ $t("pages.system.linkManager.generalInformation") }}
                            </el-divider>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.cols.slug') }}</label>
                                <el-form-item prop="slug">
                                    <el-input v-model="form.data.slug" type="text"/>
                                </el-form-item>
                            </div>

                            <el-divider content-position="center">
                                {{ $t("pages.system.linkManager.seoSettings") }}
                            </el-divider>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.seoCols.title') }}</label>
                                <el-form-item prop="title">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.seoCols.description') }}</label>
                                <el-form-item prop="description">
                                    <el-input v-model="form.data.description" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.seoCols.keywords') }}</label>
                                <el-form-item prop="keyword">
                                    <el-input v-model="form.data.keyword" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>
                            <div class="row">
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.seoCols.noIndex') }}</label>
                                    <el-form-item prop="no_index">
                                        <el-radio-group v-model="form.data.no_index">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.seoCols.hideSitemap') }}</label>
                                    <el-form-item prop="hide_sitemap">
                                        <el-radio-group v-model="form.data.hide_sitemap">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_redirect" ref="redirectModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitRedirect()" :model="form.data" ref="redirectForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                             <template v-if="!form.updateStatus">
                                <!--<div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('common.language') }}</label>
                                    <el-form-item prop="locale" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.locale" class="w-100" :placeholder="$t('common.chooseSelect')" :disabled="form.updateStatus">
                                            <el-option v-for="(language, languageIndex) in $root.languages" :key="languageIndex" :value="language.code" :label="language.name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>-->
                                 <div class="fv-row mb-7">
                                     <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.cols.type') }}</label>
                                     <el-form-item prop="linkable_type" :rules="$validation.getMessage(['required'])">
                                         <el-select v-model="form.data.linkable_type" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                                             <el-option value="301" label="301"></el-option>
                                             <el-option value="302" label="302"></el-option>
                                         </el-select>
                                     </el-form-item>
                                 </div>

                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.redirectCols.slug') }}</label>
                                    <el-form-item prop="slug" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.slug" type="text" />
                                    </el-form-item>
                                </div>
                            </template>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.linkManager.redirectCols.redirectSlug') }}</label>
                                <el-form-item prop="redirect_slug" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.redirect_slug" type="text"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: "",
                },
                {
                    name: this.$t("pages.system.linkManager.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.system.linkManager.cols.type"),
                    key: "linkable_type"
                },
                {
                    name: this.$t("pages.system.linkManager.cols.slug"),
                    scopedSlots: {customRender: "slug"}
                },
                {
                    name: this.$t("common.language"),
                    scopedSlots: {customRender: "locale"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.linkManager.table;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.linkManager"), [this.$t("menu.systemManagement")]);
        this.$store.dispatch('linkManager/get', {
            page: {},
            filterData: {
                sort: 'id:desc'
            }
        });
    },
    methods:{
        newRedirect(){
            this.form.updateStatus = false;
            this.form.data = {
                language_id: this.$root.defaultLanguage.id,
            };
            this.form.title = this.$t("pages.system.linkManager.newRedirect");
            this.showModal(this.$refs.redirectModal);
        },
        fetchLink(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.system.linkManager.editLink");
            this.axios.get(this.endpoints['link_manager']+ '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;

                this.showModal(this.$refs[((['301', '302'].includes(data.linkable_type)) ? 'redirectModal' : 'linkModal')]);
            });
        },
        onSubmitRedirect(){
            this.$refs.redirectForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.updateEvent('redirectModal');
                    } else {
                        this.axios.post(this.endpoints['link_manager'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.redirectModal);
                            });
                        }).catch(error => {
                            error.response.data.message = this.$t('pages.system.linkManager.responseMessages.' + error.response.data.message, error.response.data.message);

                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        onSubmitLink(){
            this.$refs.linkForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.updateEvent('linkModal');
                    }
                } else {
                    return false;
                }
            });
        },
        updateEvent(fromModal){
            this.axios.put(this.endpoints['link_manager'] + '/' + this.form.data.id, this.form.data).then(response => {
                this.onResponse(response.data, () => {
                    this.refreshTable();
                    this.hideModal(this.$refs[fromModal]);
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.form.loading = false;
            });
        },
        deleteRecord(id){
            this.$store.dispatch("linkManager/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRedirect();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("linkManager/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("linkManager/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'id:desc'
                }, filterData)
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>